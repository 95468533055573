<template>
  <div class="popup_wrap oog_info_pop" style="width:1050px; height: 300px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX050P081.003') }}</h1> <!-- B/L OOG Entry -->

      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:5%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:18%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:9%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" rowspan="2"></th>
              <th scope="col" rowspan="2">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th scope="col" rowspan="2">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th scope="col" rowspan="2">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th scope="col" rowspan="2">{{ $t('msg.ONEX050P081.004') }}</th> <!-- Cargo Type -->
              <th scope="col" rowspan="2">{{ $t('msg.ONEX050P081.005') }}</th> <!-- F/E -->
              <th scope="col" colspan="5">{{ $t('msg.ONEX050P081.006') }}</th> <!-- Over(㎝) -->
            </tr>
            <tr>
              <th class="border_left">OH</th>
              <th>OF</th>
              <th>OB</th>
              <th>OWP</th>
              <th>OWS</th>
            </tr>
          </thead>
          <tbody v-if="resultList.length > 0">
            <tr v-for="(vo, idx) in resultList" :key="'cntrOog_' + idx">
              <td>
                <input
                  type="checkbox"
                  :id="'oogSelect_' + idx"
                  class="oogSelect"
                  :value="idx"
                >
                <label :for="'oogSelect_' + idx"></label>
              </td>
              <td>{{ idx + 1 }}</td>
              <td>{{ vo.cntrSzCdNm }}</td>
              <td>{{ vo.cntrTypCd }}</td>
              <td>{{ vo.cgoTypCdNm }}</td>
              <td>{{ vo.feCatCd }}</td>
              <td>{{ vo.ovrHght }}</td>
              <td>{{ vo.ovrFwdLen }}</td>
              <td>{{ vo.ovrBwrdLen }}</td>
              <td>{{ vo.ovrPortLen }}</td>
              <td>{{ vo.ovrStbdLen }}</td>
            </tr>
          </tbody>
          <tbody v-if="resultList.length === 0">
            <td colspan="11">{{ $t('msg.CMBA660.005') }}</td>
          </tbody>
        </table>
        <p class="txt_desc">{{ $t('msg.ONEX050P081.007') }}</p>
      </div>

      <div class="text_center mt20">
        <a class="button lg blue font_16" @click="fnOOGSelect()">{{ $t('msg.CSBK100.178') }}</a>
        <a class="button lg gray font_16" @click="$emit('close')">{{ $t('msg.CSBK100.179') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'BLContainerOOGPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          modifyFlag: 'I',
          blNo: '',
          bkgNo: '',
          idx: ''
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      resultList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const arrCdId = ['01031', '01038']

      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res
      })

      const params = {
        bkgNo: this.parentInfo.bkgNo,
        modifyFlag: this.parentInfo.modifyFlag,
        blNo: this.parentInfo.blNo
      }

      await trans.findOOGInfo(params).then(res => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          const cntrSzList = this.commonCodes['01031']
          const cgoTypCdList = this.commonCodes['01038']

          result.forEach(c => {
            const cntrSzInfo = cntrSzList.filter(vo => vo.cd === c.cntrSzCd)
            if (cntrSzInfo !== undefined && cntrSzInfo.length > 0) {
              c.cntrSzCdNm = cntrSzInfo[0].cdNm
            }

            const cgoTypInfo = cgoTypCdList.filter(vo => vo.cd === c.cgoTypCd)
            if (cgoTypInfo !== undefined && cgoTypInfo.length > 0) {
              c.cgoTypCdNm = cgoTypInfo[0].cdNm
            }
          })

          this.resultList = result
        }
      })
    },
    fnOOGSelect () {
      const div = document.querySelector('.oog_info_pop')
      const checkbox = div.querySelector('.oogSelect:checked')

      if (checkbox === null) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK387'))
        return
      }

      if (checkbox.length > 1) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK388'))
        return
      }

      const idx = checkbox.value
      const info = this.resultList[idx]

      info.selectIdx = this.parentInfo.idx

      this.$emit('selectFunc', info)
      this.$emit('close')
    }
  }
}
</script>
