var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap oog_info_pop",
      staticStyle: { width: "1050px", height: "300px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.003"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col", rowspan: "2" } }),
                _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.004"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.005"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col", colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.006"))),
                ]),
              ]),
              _vm._m(1),
            ]),
            _vm.resultList.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.resultList, function (vo, idx) {
                    return _c("tr", { key: "cntrOog_" + idx }, [
                      _c("td", [
                        _c("input", {
                          staticClass: "oogSelect",
                          attrs: { type: "checkbox", id: "oogSelect_" + idx },
                          domProps: { value: idx },
                        }),
                        _c("label", { attrs: { for: "oogSelect_" + idx } }),
                      ]),
                      _c("td", [_vm._v(_vm._s(idx + 1))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrSzCdNm))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(vo.cgoTypCdNm))]),
                      _c("td", [_vm._v(_vm._s(vo.feCatCd))]),
                      _c("td", [_vm._v(_vm._s(vo.ovrHght))]),
                      _c("td", [_vm._v(_vm._s(vo.ovrFwdLen))]),
                      _c("td", [_vm._v(_vm._s(vo.ovrBwrdLen))]),
                      _c("td", [_vm._v(_vm._s(vo.ovrPortLen))]),
                      _c("td", [_vm._v(_vm._s(vo.ovrStbdLen))]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.resultList.length === 0
              ? _c("tbody", [
                  _c("td", { attrs: { colspan: "11" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.CMBA660.005"))),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.007"))),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt20" }, [
          _c(
            "a",
            {
              staticClass: "button lg blue font_16",
              on: {
                click: function ($event) {
                  return _vm.fnOOGSelect()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
          ),
          _c(
            "a",
            {
              staticClass: "button lg gray font_16",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.179")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "border_left" }, [_vm._v("OH")]),
      _c("th", [_vm._v("OF")]),
      _c("th", [_vm._v("OB")]),
      _c("th", [_vm._v("OWP")]),
      _c("th", [_vm._v("OWS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }